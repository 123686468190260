import "./App.css";
import Button from "./components/Button";
import Features from "./components/Features";
import ImageScrollList from "./components/ImageScrollList";
import Roads from "./resources/roads.png";
import teamJson from "./resources/team.json";
import scrn1 from "./resources/screenshots/1.png";
import scrn2 from "./resources/screenshots/3.png";
import scrn3 from "./resources/screenshots/2.png";

import { useRef, useEffect } from "react";
import Team from "./components/Team";

import { faPencil, faUsers, faLocationDot, faGlobe } from '@fortawesome/free-solid-svg-icons'
import Download from "./components/Download";

const apkUrl = "https://netmark-web-public.s3.eu-central-1.amazonaws.com/Netmark.apk"
const images = [scrn1, scrn2, scrn3, scrn1, scrn2, scrn3, scrn1, scrn2, scrn3];
const features = [
  {
    icon: faPencil,
    title: "Create",
    description:
      "Post 2d and 3d drawings, text, and even audio into real space",
    scale: 0.7,
  },
  {
    icon: faUsers,
    title: "Interact",
    description: "Remix, react, and respond to creations you find in the world",
    scale: 0.7,
  },
  {
    icon: faLocationDot,
    title: "Discover",
    description:
      "See your friend’s creations all over the world in the interactive map",
    scale: 0.4,
  },
  {
    icon: faGlobe,
    title: "Share",
    description:
      "Export your creations to other apps like Instagram, Snapchat, or TikTok",
    scale: 0.6,
  },
];

function App() {
  const learnTarget = useRef(null);
  const downloadTarget = useRef(null);

  const scrollToTarget = (target) => {
    if (target.current) {
      target.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#download') {
      const downloadSection = document.getElementById('download');
      if (downloadSection) {
        downloadSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="intro foreground">
        <div className="intro-logo primary-font"><span>Netmark</span></div>
        <div className="intro-text secondary-font">
          <p>
            Hi, we are NETMARK, your path towards digital freedom
          </p>
          <p>
            NETMARK is a geolocation-based AR social network that empowers you to leave your 
            mark anywhere, anytime. Our main aim is to establish a comfortable and welcoming 
            environment for expressing uniqueness and individualism.
          </p>
          {/*<div className="container">*/}
          {/*  <Button text="Learn More" onClick={() => scrollToTarget(learnTarget)} />*/}
          {/*  <Button text="Download Now" onClick={() => scrollToTarget(downloadTarget)} />*/}
          {/*</div>*/}
        </div>
      </div>

      {/*<div className="images-row">*/}
      {/*  <div className="roads-image-holder">*/}
      {/*    <div className="top-to-bottom-gradient"></div>*/}
      {/*    <div className="bottom-to-top-gradient"></div>*/}
      {/*    <img src={Roads} alt="roads" className="roads-image" />*/}
      {/*  </div>*/}
      {/*  <ImageScrollList images={images} />*/}
      {/*</div>*/}

      {/*<div ref={learnTarget} className="video-container">*/}
      {/*  <div className="top-to-bottom-gradient-large"></div>*/}
      {/*  <div className="bottom-to-top-gradient-large"></div>*/}
      {/*  <div className="leave-mark-text primary-header">Leave Your Mark</div>*/}
      {/*  */}
      {/*  <video className="responsive-video" autoPlay loop muted playsInline>*/}
      {/*    <source src="/videos/zvd3tb.mov" type="video/mp4" />*/}
      {/*    Your browser does not support the video tag.*/}
      {/*  </video>*/}
      {/*  */}
      {/*</div>*/}

      <Features features={features} />

      <Team team={teamJson.team} />

      <div id="download" ref={downloadTarget} >
        <Download appUrl={apkUrl} />
      </div>
    </div>
  );
}

export default App;
