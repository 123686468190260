import React from "react";
import "./Download.css";
import Button from "./Button";

const Download = ({ appUrl }) => {

  const redirect = () => {
    sendDownload();
    const link= document.createElement('a');
    link.href = appUrl;
    link.download = appUrl;
    link.click();
  }

  const sendDownload = () => {
    fetch("web/download", {
      method: "POST"
    });
  }

  return (
    <div className="download-row">
      <div className="primary-header">Download</div>
      <div className="download-container">
        <div className="container secondary-font download-description">
            <span className="feature-description download-header">
                Get our application for your device
                by clicking the button below
            </span>
            <div className="download-buttons">
              <div className="download-device">
                <span className="feature-description">
                    For android
                </span>
                <Button text="Download" onClick={redirect} />
              </div>
              <div className="download-device">
                <span className="feature-description">
                    For iOS
                </span>
                <Button text="Coming Soon" /* onClick={redirect} */ className="btn-disabled" />
              </div>
            </div>
        </div>
        <img className="download-qr" src="/images/phone.png" />
      </div>
    </div>
  );
};

export default Download;
