import React from "react";
import "./Features.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Features = ({ features }) => {
  return (
    <div className="feature-row">
      <div className="primary-header">Features</div>
      <div className="feature-list">
        {features.map((feature, index) => {
          return (
            <div key={index} className="feature-container">
              <div className="feature-icon-container">
                <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
              </div>
              <div className="feature-text">
                <div className="feature-title primary-font">{feature.title}</div>
                <div className="feature-description secondary-font">{feature.description}</div>
              </div>
            </div>
          );
        })}
    </div>
    </div>
  );
};

export default Features;
